import React, { useEffect } from "react";
import { Box } from "@mui/material";
import styled, { keyframes } from "styled-components";
import OurServices from "./OurServices";
import ContactSection from "./Contact";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: -2.1rem;
  animation: ${fadeIn} 1s ease forwards;
`;

const StyledLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000000;
  left: 50%;
  width: 30%;
  transform: translateX(-50%);
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #000000;
  text-align: center;
  z-index: 1;
  position: relative;
  margin-bottom: 2rem;
  padding-top: 2rem;
  animation: ${slideIn} 1s ease forwards;
`;

function About() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <div style={{ marginTop: "70px" }}>
      <TitleWrapper>
        <StyledLine />
        <Title>About Us</Title>
        <StyledLine />
      </TitleWrapper>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          borderRadius: "16px",
        }}
      ></Box>

      <Box
        sx={{
          borderRadius: "16px",
          padding: "20px",
        }}
      >
        <OurServices />
      </Box>

      <Box>
        <ContactSection />
      </Box>
    </div>
  );
}

export default About;
