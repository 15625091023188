import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
});

const navItems = ["Home", "Menu",  "About", "Catering","Contact"];

export default function DrawerAppBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const prevScrollY = useRef(0);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      // Determine scrolling direction (up or down)
      const isScrollingUp = scrollTop < prevScrollY.current;
      prevScrollY.current = scrollTop;

      // Adjust the threshold as needed (e.g., 50)
      const showThreshold = 50;

      // Show the navigation bar instantly when scrolling up
      setIsNavbarVisible(isScrollingUp || scrollTop < showThreshold);
      setShowBackToTop(scrollTop > window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drawerBackground = "#000000";

  const drawerTextStyle = {
    color: "#C7A57E",
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "25px",
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <AppBar style={{ display: isNavbarVisible ? "block" : "none" }}>
          <Toolbar>
            <Link to="/" style={{ display: "inline-block" }}>
              <div
                style={{
                  width: "90px",
                  height: "90px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  border: "2px solid #C7A57E",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "9px",
                }}
              >
                <img
                  src={require("../assets/images/logo.png")}
                  alt="Logo"
                  style={{
                    width: "90px",
                    height: "90px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
            </Link>
            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <MenuIcon onClick={toggleDrawer} sx={{ color: "#C7A57E" }} />
              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer}
                sx={{ "& .MuiDrawer-paper": { background: drawerBackground } }}
              >
                <Box
                  sx={{
                    width: 250,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: "100%",
                    padding: "10px",
                  }}
                  role="presentation"
                  onClick={toggleDrawer}
                  onKeyDown={toggleDrawer}
                >
                  <Link to="/" style={{ display: "inline-block" }}>
                    <div
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        border: "2px solid #C7A57E",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "50px",
                      }}
                    >
                      <img
                        src={require("../assets/images/logo.png")}
                        alt="Logo"
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  </Link>
                  <CloseIcon
                    onClick={toggleDrawer}
                    sx={{
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      cursor: "pointer",
                      color: "#C7A57E",
                    }}
                  />
                  <List>
                    {navItems.map((item) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        key={item}
                        to={`/${item.toLowerCase()}`}
                      >
                        <ListItem button key={item}>
                          <ListItemText
                            primary={item}
                            primaryTypographyProps={{
                              style: drawerTextStyle,
                            }}
                          />
                        </ListItem>
                      </Link>
                    ))}
                    {/* Add the Order Now link to the drawer */}
                    <a
                      href="https://order.online/store/shawarma-station-coldwater-27653865/?hideModal=true&pickup=true"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button>
                        <ListItemText
                          primary="Order Now"
                          style={{ color: "#ffffff" ,
                            background: "#000000",
                            border: "2px solid #c7a57e",
                            borderRadius: "1px",
                            paddingRight:"15px",
                            paddingLeft:"15px"
                          }}
                        />
                      </ListItem>
                    </a>
                  </List>
                </Box>
              </Drawer>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "flex" } }}>
              <Box>
                {navItems.map((item) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    key={item}
                    to={`/${item.toLowerCase()}`}
                  >
                    <Button
                      key={item}
                      style={{ color: "#C7A57E", marginRight: "10px" }}
                    >
                      {item}
                    </Button>
                  </Link>
                ))}
                <a
                  href="https://order.online/store/shawarma-station-coldwater-27653865/?hideModal=true&pickup=true"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button style={{ color: "#ffffff" ,background: "#000000",border: "2px solid #c7a57e", borderRadius: "1px"}}>Order Now</Button>
                </a>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box>
          <Toolbar />
        </Box>
        {/* Back-to-Top Arrow */}
        {showBackToTop && (
          <Box
            sx={{
              position: "fixed",
              bottom: "10px",
              left: "10%", // Center the arrow horizontally
              transform: "translateX(-50%)", // Center horizontally
              cursor: "pointer",
              zIndex: 1000,
              "&:hover .arrow": {
                animation: "bounce 0.8s infinite alternate", // Add animation on hover
              },
            }}
          >
            <div
              className="arrow" // Add arrow class for animation
              onClick={scrollToTop}
              style={{
                width: "40px", // Adjust width and height as needed
                height: "40px",
                backgroundColor: "#C7A57E", // Background color
                borderRadius: "50%", // Make it a circle
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <KeyboardArrowUpIcon
                fontSize="large" // Adjust the size as needed
                style={{ color: "black" }} // Icon color
              />
            </div>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
