import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const navItems = ["Home", "Menu", "About","Catering", "Contact"];
const socialMediaLinks = [
  { name: "Facebook", link: "https://facebook.com" },
  { name: "Twitter", link: "https://twitter.com" },
  { name: "Instagram", link: "https://instagram.com" },
];

const Footer = () => {
  return (
    <Box sx={styles.footer}>
      <Box sx={styles.footerContent}>
        <Box sx={styles.navContainer}>
          {navItems.map((item) => (
            <Link style={styles.link} key={item} to={`/${item.toLowerCase()}`}>
              <Button key={item} sx={styles.navButton}>
                {item}
              </Button>
            </Link>
          ))}
          <a
            style={styles.link}
            href="https://order.online/store/shawarma-station-coldwater-27653865/?hideModal=true&pickup=true"
            target="_blank"
            rel="noopener noreferrer"
          >
                  <Button style={{ color: "#ffffff" ,background: "#000000",border: "2px solid #c7a57e", borderRadius: "1px"}}>Order Now</Button>
                  </a>
        </Box>
        <div style={styles.logoContainer}>
          <Link to="/">
            <img
              src={require("../assets/images/logo.png")}
              alt="Logo"
              style={styles.logo}
            />
          </Link>
        </div>
        <Box sx={styles.socialMediaContainer}>
          {socialMediaLinks.map((social) => (
            <a
              style={styles.socialLink}
              key={social.name}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {social.name}
            </a>
          ))}
        </Box>
        <a
          style={styles.copyright}
          href="https://24techdesign.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Copyright © 2023. Developed by{" "}
          <span style={{ color: "#e69138" }}>@24TechDesign</span>
        </a>
      </Box>
    </Box>
  );
};

const styles = {
  footer: {
    backgroundColor: "#000000",
    color: "#fff",
    padding: "20px 0",
  },
  footerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
  },
  navContainer: {
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    justifyContent: "center",
    marginBottom: "15px",
    marginLeft: "30px",
  },
  link: {
    textDecoration: "none",
  },
  navButton: {
    color: "#C7A57E",
  },
  logoContainer: {
    width: "140px",
    height: "140px",
    borderRadius: "50%",
    overflow: "hidden",
    border: "2px solid #C7A57E",
    marginBottom: "20px",
  },
  logo: {
    width: "140px",
    height: "140px",
    objectFit: "cover" as const, // Specify objectFit as 'cover'
  },
  socialMediaContainer: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
  },
  socialLink: {
    color: "#C7A57E",
    textDecoration: "none",
  },
  copyright: {
    color: "#C7A57E",
    textDecoration: "none",
    marginTop: "20px",
  },
};

export default Footer;
