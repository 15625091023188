import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";

interface CardProps {
  title: string;
  imageSrc: string;
  description: string;
  index: number;
}

const Card: React.FC<CardProps> = ({ title, imageSrc, description, index }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleLearnMore = () => {
    if (index === 0) {
      window.location.href = "/menu";
    } else if (index === 1) {
      window.location.href = "/catering";
    }
  else if (index === 2) {
    window.location.href = "/catering";
  }
     else {
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <StyledCard>
        <CardImage src={imageSrc} alt={title} />
        <CardContent>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
          {(index === 0 || index === 1 || index === 2) && (
            <LearnMoreButton onClick={handleLearnMore}>
              Learn More
            </LearnMoreButton>
          )}
        </CardContent>
      </StyledCard>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Coming Soon Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "50%",
            margin: "auto",
            borderRadius: "8px",
            padding: "20px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <ModalContent>
          <ModalTitle>Coming Soon</ModalTitle>
          <ModalDescription>
            We're working on something exciting! Stay tuned for updates.
          </ModalDescription>
          <CloseButton onClick={closeModal}>Close</CloseButton>
        </ModalContent>
      </Modal>
    </>
  );
};

const StyledCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
`;

const CardContent = styled.div`
  padding: 16px;
`;

const CardTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: black;
`;

const CardDescription = styled.p`
  color: black;
`;

const LearnMoreButton = styled.button`
  font-size: 1rem;
  padding: 14px 16px;
  border-radius: 5px;
  background: linear-gradient(to right, #daa520, #000000);
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: linear-gradient(to right, #000000, #daa520);
  }
`;

const ModalContent = styled.div`
  text-align: center;
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: black;
`;

const ModalDescription = styled.p`
  color: black;
`;

const CloseButton = styled.button`
  font-size: 1rem;
  padding: 10px 16px;
  border-radius: 5px;
  background: linear-gradient(to right, #000000, #3079ab);
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: linear-gradient(to right, #000000, #daa520);
  }
`;

const WhatWeOffer: React.FC = () => {
  return (
    <WhatWeOfferWrapper>
      <WhatWeOfferContainer>
        <WhatWeOfferItem>
          <Card
            title="Our Menu"
            imageSrc={require("../assets/images/whatweoffer1.jpg")}
            description="
            Explore the excellence of Shawarma Station's 100% halal menu. Visit our menu page for details on your favorites."
            index={0}
          />
        </WhatWeOfferItem>

        <WhatWeOfferItem>
          <Card
            title="Catering"
            imageSrc={require("../assets/images/whatweoffer2.jpg")}
            description="Shawarma Station provides comprehensive catering services, offering diverse menus suitable for luncheons and various on-site events.."
            index={1}
          />
        </WhatWeOfferItem>

        <WhatWeOfferItem>
          <Card
            title="Banquets"
            imageSrc={require("../assets/images/whatweoffer3.jpg")}
            description="Host your special occasions at Shawarma Station! We cater to a variety of events, including graduations, engagements, bridal showers, and more. Explore our page for a personalized quote."
            index={2}
          />
        </WhatWeOfferItem>
      </WhatWeOfferContainer>
    </WhatWeOfferWrapper>
  );
};

const WhatWeOfferContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start; /* Align items to the start for larger screens */

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 428px) {
    align-items: center;
  }
`;

const WhatWeOfferItem = styled.div`
  flex: 1;
  margin: 1rem;
  text-align: center;
  width: 100%;

  @media (max-width: 768px) {
    margin: 0.5rem;
    flex-basis: calc(
      50% - 1rem
    ); /* Two items side by side on smaller screens */
  }
`;

const WhatWeOfferWrapper = styled.div`
  margin-top: 5rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export default WhatWeOffer;
