import React, { useEffect } from "react";
import styled from "styled-components";
import OurServices from "./OurServices";
import WhatWeOffer from "./WhatWeOffer";

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
`;

const BackgroundContainer = styled.div`
  height: 90vh;
  width: 100%;
  background-image: url(${require("../assets/images/dishes.webp")});
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  position: absolute;
  top: 45px; /* Adjust the top margin based on your design */
  left: 0;
`;

const ContentContainer = styled.div`
  position: relative;
  text-align: center;
  z-index: 2;
  margin-bottom: 10px; /* Adjust the margin-bottom based on your design */
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: #f1975b;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  background-color: rgba(17, 24, 31, 0.7);
  padding: 0.5rem;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 3rem;
  color: #f1975b;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  background-color: rgba(17, 24, 31, 0.7);
  padding: 0.5rem;
`;

const OrderButton = styled.a`
  font-size: 1.2rem;
  padding: 1rem 2rem;
  border-radius: 5px;
  background: #000000;
  color: #ffffff;
  border: 2px solid #c7a57e; /* Add white border */
  cursor: pointer;
  transition: all 0.3s ease;
  position: fixed;
  bottom: 20px;
  right: 20px;
  text-decoration: none;
  z-index: 999; /* Add a higher z-index value */

  @media screen and (max-width: 768px) {
    max-width: 120px; /* Set max-width to ensure the button doesn't become too wide */
    text-align: center;
    right: 20px;
    bottom: 20px;
  }

  &:hover {
    background: linear-gradient(to right, #000000, #c7a57e);
    border-color: #daa520; /* Change border color on hover */
  }
`;

const HomePage: React.FC = () => {
  const handleClick = () => {
    window.location.href =
      "https://order.online/store/shawarma-station-coldwater-27653865/?hideModal=true&pickup=true";
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <HomePageContainer>
        <BackgroundContainer />
        <ContentContainer>
          <Title>Shawarma Station</Title>
          <Subtitle>
            Elevate your dining experience with Mediterranean cuisine
          </Subtitle>
          <OrderButton onClick={handleClick}>Order Now</OrderButton>
        </ContentContainer>
      </HomePageContainer>
      <OurServices />
      <WhatWeOffer />
    </>
  );
};

export default HomePage;
