import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";

interface ServiceProps {
  title: string;
  imageSrc: string;
  description: string;
}

const Service: React.FC<ServiceProps> = ({ title, imageSrc, description }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <StyledCard>
        <CardImage src={imageSrc} alt={title} />
        <CardContent>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardContent>
      </StyledCard>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Coming Soon Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "50%",
            margin: "auto",
            borderRadius: "8px",
            padding: "20px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <ModalContent>
          <ModalTitle>Coming Soon</ModalTitle>
          <ModalDescription>
            We're working on something exciting! Stay tuned for updates.
          </ModalDescription>
          <CloseButton onClick={closeModal}>Close</CloseButton>
        </ModalContent>
      </Modal>
    </>
  );
};

const StyledCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
`;

const CardContent = styled.div`
  padding: 16px;
`;

const CardTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: black;
`;

const CardDescription = styled.p`
  color: black;
`;

const ModalContent = styled.div`
  text-align: center;
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: black;
`;

const ModalDescription = styled.p`
  color: black;
`;

const CloseButton = styled.button`
  font-size: 1rem;
  padding: 10px 16px;
  border-radius: 5px;
  background: linear-gradient(to right, #000000, #3079ab);
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: linear-gradient(to right, #000000, #daa520);
  }
`;

const OurServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const OurServicesItem = styled.div`
  flex-basis: calc(
    33.33% - 1rem
  ); /* Each item takes about one-third of the width with some margin */
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    flex-basis: calc(
      100% - 1rem
    ); /* On smaller screens, each item takes full width with some margin */
  }
`;

const OurServicesWrapper = styled.div`
  margin-top: 5rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const OurServices: React.FC = () => {
  return (
    <OurServicesWrapper>
      <OurServicesContainer>
        <OurServicesItem>
          <Service
            title="Special Events"
            imageSrc={require("../assets/images/banquet.jpeg")}
            description="Discover exceptional catering services and banquet choices designed to elevate every special occasion."
          />
        </OurServicesItem>

        <OurServicesItem>
          <Service
            title="Delivery Services"
            imageSrc={require("../assets/images/food-delivery.jpeg")}
            description="Elevate your experience with complimentary concierge delivery. Reserve your slot today!"
          />
        </OurServicesItem>

        <OurServicesItem>
          <Service
            title="100% Halal"
            imageSrc={require("../assets/images/halal.jpeg")}
            description="Indulge in delectable Mediterranean cuisines, with each dish proudly certified 100% halal."
          />
        </OurServicesItem>
      </OurServicesContainer>
    </OurServicesWrapper>
  );
};

export default OurServices;
