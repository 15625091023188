import React from "react";
import Modal from "react-modal";

type Location = "coldwater" | "sturgis";

interface MenuModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  selectedMenu: number;
  onNavigate: (direction: number) => void;
  location: Location; // Updated type here
}

const MenuModal: React.FC<MenuModalProps> = ({
  isOpen,
  onRequestClose,
  selectedMenu,
  onNavigate,
  location, // Include this prop in the component
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={`Menu ${selectedMenu} Modal`}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          width: "90%",
          maxWidth: "600px",
          height: "70vh",
          overflow: "auto",
          borderRadius: "8px",
          padding: "22px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          position: "fixed",
          top: "58%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <div
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "5px",
          cursor: "pointer",
          fontSize: "24px",
          fontWeight: "bold",
          color: "#000",
        }}
      >
        &#10006;
      </div>
      <div style={{ position: "relative" }}>
        <img
          src={`/menus/${location}/menu${selectedMenu}.webp`} // Updated path here
          alt={`Menu ${selectedMenu}`}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            borderRadius: "8px",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-15px",
            transform: "translateY(-50%)",
            cursor: "pointer",
            fontSize: "35px",
            fontWeight: "bold",
            color: "#000",
          }}
          onClick={() => onNavigate(-1)}
        >
          &lt;
        </div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "-15px",
            transform: "translateY(-50%)",
            cursor: "pointer",
            fontSize: "35px",
            fontWeight: "bold",
            color: "#000",
          }}
          onClick={() => onNavigate(1)}
        >
          &gt;
        </div>
      </div>
    </Modal>
  );
};

export default MenuModal;
