import React, { useEffect } from "react";
import { Box, Card, Grid, Typography, IconButton } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: 3rem;
  animation: ${fadeIn} 1s ease forwards;
`;

const StyledLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000000;
  left: 50%;
  width: 30%;
  transform: translateX(-50%);
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #000000;
  text-align: center;
  z-index: 1;
  position: relative;
  margin-bottom: 2rem;
  padding-top: 2rem;
  animation: ${slideIn} 1s ease forwards;
`;

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div style={{ marginTop: "70px" }}>
      <TitleWrapper>
        <StyledLine />
        <Title>Contact</Title>
        <StyledLine />
      </TitleWrapper>

      <Box sx={styles.contactContainer}>
        <Grid container spacing={4} justifyContent="center">
          {/* Call Card */}
          <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
            <a href="tel:+15179240446" style={{ textDecoration: "none" }}>
              <Card sx={styles.contactCard}>
                <IconButton sx={styles.iconButton}>
                  <PhoneIcon sx={{ fontSize: 40, color: "#4CAF50" }} />
                </IconButton>
                <Typography variant="h6" sx={styles.cardTitle}>
                  Call Us
                </Typography>
                <Typography variant="body1" sx={styles.cardText}>
                  (517) 924-0446
                </Typography>
              </Card>
            </a>
          </Grid>

          {/* Email Card */}
          <Grid item xs={12} md={6} order={{ xs: 2, md: 2 }}>
            <a
              href="mailto:moabdul955@gmail.com"
              style={{ textDecoration: "none" }}
            >
              <Card sx={styles.contactCard}>
                <IconButton sx={styles.iconButton}>
                  <EmailIcon sx={{ fontSize: 40, color: "#2196F3" }} />
                </IconButton>
                <Typography variant="h6" sx={styles.cardTitle}>
                  Email Us
                </Typography>
                <Typography variant="body1" sx={styles.cardText}>
                  moabdul955@gmail.com
                </Typography>
              </Card>
            </a>
          </Grid>

          {/* Location 1 Card */}
          <Grid item xs={12} md={6} order={{ xs: 3, md: 3 }}>
            <a
              href="https://maps.app.goo.gl/jLbKnqtYCU9wEzut5"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Card sx={styles.contactCard}>
                <IconButton sx={styles.iconButton}>
                  <LocationOnIcon sx={{ fontSize: 40, color: "#F44336" }} />
                </IconButton>
                <Typography variant="h6" sx={styles.cardTitle}>
                  Sturgis
                </Typography>
                <Typography variant="body2" sx={styles.cardText}>
                  1507 E Chicago Rd, Sturgis, MI 49091, USA
                </Typography>
              </Card>
            </a>
          </Grid>

          {/* Location 2 Card */}
          <Grid item xs={12} md={6} order={{ xs: 4, md: 4 }}>
            <a
              href="https://maps.app.goo.gl/dn6Mxkd629cgf7cE7"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Card sx={styles.contactCard}>
                <IconButton sx={styles.iconButton}>
                  <LocationOnIcon sx={{ fontSize: 40, color: "#F44336" }} />
                </IconButton>
                <Typography variant="h6" sx={styles.cardTitle}>
                  Coldwater
                </Typography>
                <Typography variant="body2" sx={styles.cardText}>
                  64 E Chicago St, Coldwater, MI 49036, USA
                </Typography>
              </Card>
            </a>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const styles = {
  contactContainer: {
    marginTop: "20px",
    borderRadius: "16px",
    padding: "20px",
    textAlign: "center",
    marginBottom: "20px",

    "@media (max-width: 600px)": {
      padding: "10px",
    },
  },
  contactCard: {
    textAlign: "center",
    p: 4,
    borderRadius: "12px",
    "&:hover": {
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
    },
    border: "solid",
    background: "#000000",
    maxWidth: "100%",
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  cardTitle: {
    fontSize: 20,
    color: "#C7A57E",
    mt: 2,
  },
  cardText: {
    color: "#fff",
  },
};

export default Contact;
