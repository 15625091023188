import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import About from "./components/About";
import Menu from "./components/Menu";
import Home from "./components/Home";
import Contact from "./components/Contact";
import CateringForm from './components/CateringForm';
import ThankYou from './components/ThankYou';

import { Route, Routes } from "react-router-dom";
import "./App.css"; // Import your global styles

function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/catering" element={<CateringForm />} />
        <Route path="/thankyou" element={<ThankYou />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
