import React, { useState } from "react";
import MenuModal from "./MenuModal";
import styled, { keyframes } from "styled-components";

const Menu = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<"coldwater" | "sturgis">("coldwater"); // Updated type here

  const openModal = (menuNumber: number) => {
    setCurrentIndex(menuNumber - 1);
    setModalOpen(true);
  };

  const closeModal = () => {
    setCurrentIndex(null);
    setModalOpen(false);
  };

  const navigateMenu = (direction: number) => {
    if (currentIndex !== null) {
      const newIndex = (currentIndex + direction + 6) % 6;
      setCurrentIndex(newIndex);
    }
  };

  const changeLocation = (location: "coldwater" | "sturgis") => { // Updated type here
    setSelectedLocation(location);
  };

  return (
    <MenuContainer>
      <TitleWrapper>
        <StyledLine />
        <Title>Our Menus</Title>
        <StyledLine />
      </TitleWrapper>

      <ButtonContainer>
        <LocationButton
          isSelected={selectedLocation === "coldwater"}
          onClick={() => changeLocation("coldwater")}
        >
          Coldwater
        </LocationButton>
        <LocationButton
          isSelected={selectedLocation === "sturgis"}
          onClick={() => changeLocation("sturgis")}
        >
          Sturgis
        </LocationButton>
      </ButtonContainer>

      <MenuRow>
        {[1, 2, 3].map((menuNumber) => (
          <MenuColumn
            key={menuNumber}
            onClick={() => openModal(menuNumber)}
            menuNumber={menuNumber}
          >
            <MenuImage
              src={`/menus/${selectedLocation}/menu${menuNumber}.webp`}
              alt={`Menu ${menuNumber}`}
            />
          </MenuColumn>
        ))}
      </MenuRow>

      <MenuRow>
        {[4, 5, 6].map((menuNumber) => (
          <MenuColumn
            key={menuNumber}
            onClick={() => openModal(menuNumber)}
            menuNumber={menuNumber}
          >
            <MenuImage
              src={`/menus/${selectedLocation}/menu${menuNumber}.webp`}
              alt={`Menu ${menuNumber}`}
            />
          </MenuColumn>
        ))}
      </MenuRow>

      <MenuModal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        selectedMenu={currentIndex !== null ? currentIndex + 1 : 0}
        onNavigate={navigateMenu}
        location={selectedLocation} // Ensure this is correctly typed
      />
    </MenuContainer>
  );
};


const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: 3rem;
  @media (max-width: 600px) {
    margin-bottom: 1.5rem;
  }
  animation: ${fadeIn} 1s ease forwards;
`;

const StyledLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000000;
  left: 50%;
  width: 170%;
  transform: translateX(-50%);
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #000000;
  text-align: center;
  position: relative;
  margin-bottom: 2rem;

  @media (max-width: 600px) {
    padding-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  animation: ${slideIn} 1s ease forwards;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const LocationButton = styled.button<{ isSelected: boolean }>`
  background-color: ${(props) => (props.isSelected ? "#C7A57E" : "#000000")};
  color: ${(props) => (props.isSelected ? "#ffffff" : "#ffffff")};
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.isSelected ? "#b5956e" : "#333333")};
    color: #ffffff;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
`;

const MenuRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
`;

const MenuColumn = styled.div<{ menuNumber: number }>`
  flex: 1;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  border-radius: 8px;
  margin: 12px;
  border: solid;
  border-color: #000000;
  padding: 2px;
  &:hover {
    transform: scale(1.05);
  }
`;

const MenuImage = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 8px;
`;

export default Menu;
