import React, { useState, useRef } from "react";
import styled, { keyframes } from "styled-components";

// Animation styles
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: -2.1rem;
  animation: ${fadeIn} 1s ease forwards;
`;

const StyledLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000000;
  left: 50%;
  width: 30%;
  transform: translateX(-50%);
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #000000;
  text-align: center;
  z-index: 1;
  position: relative;
  margin-bottom: 2rem;
  padding-top: 2rem;
  animation: ${slideIn} 1s ease forwards;
`;

const CateringForm: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    eventDate: "",
    numberOfGuests: "",
    additionalInfo: "",
    location: "Coldwater", // Default location
    deliveryAddress: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState<null | boolean>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(form.current!);

    fetch('https://formspree.io/f/mvgpkzae', {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => {
      setIsSubmitting(false);
      if (response.ok) {
        setSubmitSuccess(true);
        window.location.href = "/thankyou"; // Redirect to the thankyou page
      } else {
        setSubmitSuccess(false);
        alert('Failed to submit form.');
      }
    })
    .catch(error => {
      setIsSubmitting(false);
      setSubmitSuccess(false);
      alert('An error occurred: ' + error.message);
    });
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <TitleWrapper>
        <StyledLine />
        <Title>Catering</Title>
        <StyledLine />
      </TitleWrapper>

      <FormContainer>
        <FormTitle>Order Form</FormTitle>
        <Form
          ref={form}
          method="POST"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="_subject" value="New Catering Request" />
          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="_next" value="/thankyou" />
          <FormGroup>
            <Label htmlFor="location">Location:</Label>
            <Select
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
            >
              <option value="Coldwater">Coldwater</option>
              <option value="Sturgis">Sturgis</option>
            </Select>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="name">Name:</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="email">Email:</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="phone">Phone:</Label>
            <Input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="eventDate">Event Date:</Label>
            <Input
              type="date"
              id="eventDate"
              name="eventDate"
              value={formData.eventDate}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="numberOfGuests">Number of Guests:</Label>
            <Input
              type="number"
              id="numberOfGuests"
              name="numberOfGuests"
              value={formData.numberOfGuests}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="deliveryAddress">Address (If Delivery):</Label>
            <Input
              id="deliveryAddress"
              name="deliveryAddress"
              value={formData.deliveryAddress}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="additionalInfo">Additional Info:</Label>
            <TextArea
              id="additionalInfo"
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <SubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </SubmitButton>
        </Form>
        {submitSuccess === false && <ErrorMessage>Failed to submit the form. Please try again.</ErrorMessage>}
      </FormContainer>
    </div>
  );
};

export default CateringForm;

// Styles remain unchanged
const FormContainer = styled.div`
  max-width: 600px;
  margin: 90px auto;
  padding: 20px;
  padding-right: 40px;
  background: #C7A57E;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1s ease-in-out;
`;

const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: black;
  font-size: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  &:focus {
    border-color: #333;
    outline: none;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  height: 100px;
  &:focus {
    border-color: #333;
    outline: none;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 15px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #555;
  }
  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  &:focus {
    border-color: #333;
    outline: none;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 15px;
`;
