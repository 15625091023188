import React from "react";
import styled, { keyframes } from "styled-components";

const ThankYou: React.FC = () => {
  return (
    <ThankYouContainer>
      <ThankYouTitle>Thank You!</ThankYouTitle>
      <ThankYouMessage>
        Your catering request has been received. We will get back to you soon.
      </ThankYouMessage>
    </ThankYouContainer>
  );
};

export default ThankYou;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ThankYouContainer = styled.div`
  max-width: 600px;
  margin: 90px auto;
  padding: 20px;
  background: #C7A57E;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: ${fadeIn} 1s ease-in-out;
`;

const ThankYouTitle = styled.h2`
  color: black;
  font-size: 24px;
  margin-bottom: 20px;
`;

const ThankYouMessage = styled.p`
  color: black;
  font-size: 18px;
`;
